import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import './App_mobile.css'
// import { BrowserRouter } from "react-router-dom";
// import Routes from './Routes'
import Grid from '@material-ui/core/Grid'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Logo from './images/logo.png'
import { Helmet } from 'react-helmet'

const TITLE = 'Leclisse'

class App extends Component {
  
  render () {  
    return (
      <div>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        <Grid container>
          <Grid item xs={5} sm={5} />
          <Grid item xs={2} sm={2}>
            <div className="header"><img src={Logo} className="logo" /></div>
          </Grid>
        </Grid>
        
        <Grid container>
            <Grid item xs={12} sm={12} className="main_img">
            
            </Grid> 
        </Grid>



      <Grid container>
        <Grid item xs={0} />
          <Grid item xs={12}>
            <h1 className="address">1059 High Street Armadale, VIC <br /> 9822 4049 </h1>
          </Grid>
        <Grid item xs={0} />
      </Grid>
      
      <Grid container>
        <Grid item xs={12}>
          <div className="map">
            <iframe frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=1059%20High%20Street%2C%20Armadale%20VIC%203143&key=AIzaSyBHvGgpTG-dk7k5GcncOoDnyv0_WnrV-wY" allowfullscreen className="iframe_map" > </iframe>
          </div>
        </Grid>
      </Grid>
      </div>
    );
  }
}

export default App;